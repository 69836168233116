import React from "react";

function QASection() {
  return (
    <div className="block sm:hidden bg-black">
      <section id="qasection">
        <div className=" text-white relative w-[90%] sm:w-[100%] mx-auto my-36  ">
          <div className="qa-container">
            <div className="qa-head text-center rounded-lg sm:absolute sm:top-[50%] sm:left-[50%] sm:transform sm:-translate-x-1/2 sm:-translate-y-1/2 sm:bg-customPurple-light/80 sm:backdrop-blur-xs sm:p-10">
              <div className="qa-title text-4xl font-euc">
                <h2>
                  Soruların Cevapları ve Daha Fazlası İçin Uygulamayı Dene!
                </h2>
              </div>
              <br />
              {/* <div className="qa-description">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Consectetur atque quia facilis eius ratione facere dignissimos
              </div> */}

              <div className="hrefImages flex justify-center gap-4">
                <a
                  href="https://apps.apple.com/tr/app/therapoloji-terapi-hipnoz/id1667121878"
                  className="shadow-custom-4-sided shadow-customWhite rounded"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/appstore.png`}
                    alt=""
                    className="w-40 rounded"
                    loading="lazy"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.mifosoft.therapoloji&hl=tr"
                  className="shadow-custom-4-sided shadow-customWhite rounded"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/googleplay.png`}
                    alt=""
                    className="w-40 rounded"
                    loading="lazy"
                  />
                </a>
              </div>
            </div>
            <div className="qa-content pt-8 flex justify-center flex-col sm:flex-row">
              <div className="qa-box my-2 w-[100%] sm:w-[20%] items-center justify-center border  border-customPurple shadow-custom-4-sided shadow-customPurple sm:shadow-customWhite rounded-xl mx-auto p-3 flex flex-col text-center">
                <div className="qa-box-title my-2 text-2xl">
                  Neden başkalarının düşüncelerini gereğinden fazla umursarız?
                </div>
              </div>
              <div className="qa-box my-2 w-[100%] sm:w-[20%] items-center justify-center border border-customPurple shadow-custom-4-sided shadow-customPurple sm:shadow-customWhite rounded-xl mx-auto p-3 flex flex-col text-center">
                <div className="qa-box-title my-2 text-2xl">
                  Bir insan neden yalan söyler?
                </div>
              </div>
              <div className="qa-box my-2 w-[100%] sm:w-[20%] items-center justify-center border border-customPurple shadow-custom-4-sided shadow-customPurple sm:shadow-customWhite rounded-xl mx-auto p-3 flex flex-col text-center">
                <div className="qa-box-title my-2 text-2xl">
                  Ergenlik çağındaki çocuğumla nasıl iletişim kurabilirim?
                </div>
              </div>
              <div className="qa-box my-2 w-[100%] sm:w-[20%] items-center justify-center border border-customPurple shadow-custom-4-sided shadow-customPurple sm:shadow-customWhite rounded-xl mx-auto p-3 flex flex-col text-center">
                <div className="qa-box-title my-2 text-2xl">
                  Nasıl özgüvenli bir insan olabilirim?
                </div>
              </div>

              <div className="qa-box my-2 w-[100%] sm:w-[20%] items-center justify-center border border-customPurple shadow-custom-4-sided shadow-customPurple sm:shadow-customWhite rounded-xl mx-auto p-3 flex flex-col text-center">
                <div className="qa-box-title my-2 text-2xl">
                  İlk başta böyle değildi, zaman geçtikçe değişti, neden?
                </div>
              </div>
            </div>
            <div className="qa-content hidden sm:flex justify-center flex-col sm:flex-row">
              <div className="qa-box my-2 w-[100%] sm:w-[20%] items-center justify-center border  border-customPurple shadow-custom-4-sided shadow-customPurple sm:shadow-customWhite rounded-xl mx-auto p-3 flex flex-col text-center">
                <div className="qa-box-title my-2 text-2xl">
                  Neden başkalarının düşüncelerini gereğinden fazla umursarız?
                </div>
              </div>
              <div className="qa-box my-2 w-[100%] sm:w-[20%] items-center justify-center border border-customPurple shadow-custom-4-sided shadow-customPurple sm:shadow-customWhite rounded-xl mx-auto p-3 flex flex-col text-center">
                <div className="qa-box-title my-2 text-2xl">
                  Bir insan neden yalan söyler?
                </div>
              </div>
              <div className="qa-box my-2 w-[100%] sm:w-[20%] items-center justify-center border border-customPurple shadow-custom-4-sided shadow-customPurple sm:shadow-customWhite rounded-xl mx-auto p-3 flex flex-col text-center">
                <div className="qa-box-title my-2 text-2xl">
                  Ergenlik çağındaki çocuğumla nasıl iletişim kurabilirim?
                </div>
              </div>
              <div className="qa-box my-2 w-[100%] sm:w-[20%] items-center justify-center border border-customPurple shadow-custom-4-sided shadow-customPurple sm:shadow-customWhite rounded-xl mx-auto p-3 flex flex-col text-center">
                <div className="qa-box-title my-2 text-2xl">
                  Nasıl özgüvenli bir insan olabilirim?
                </div>
              </div>

              <div className="qa-box my-2 w-[100%] sm:w-[20%] items-center justify-center border border-customPurple shadow-custom-4-sided shadow-customPurple sm:shadow-customWhite rounded-xl mx-auto p-3 flex flex-col text-center">
                <div className="qa-box-title my-2 text-2xl">
                  İlk başta böyle değildi, zaman geçtikçe değişti, neden?
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default QASection;
