import React, { useState, useRef, useCallback, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight } from 'lucide-react';
// Import your images (replace with actual import paths)
import karamsar from "../karamsar.jpg";
import endişeli from "../endişeli.jpg";
import canısıkkın from "../canısıkkın.jpg";
import kararsız from "../kararsız.jpg";
import kızgın from "../kızgın.jpg";
import stresli from "../stresli.jpg";
import üzgün from "../üzgün.jpg";
import mutlu from "../mutlu.jpg";
import yetersiz from "../yetersiz.jpg";
import umutlu from "../umutlu.jpg";
import depresif from "../depresif.jpg";


// Mock veri sağlayıcı fonksiyon
const fetchInfoBoxes = async (page, limit = 11) => {
  // Gerçek bir API çağrısı simülasyonu
  await new Promise(resolve => setTimeout(resolve, 500)); // Yapay gecikme

  const baseBoxes = [
    {
      id: 1,
      image: karamsar,
      title: "Karamsar",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    },
    {
      id: 2,
      image: canısıkkın,
      title: "Canısıkkın",
      description: "Consectetur adipisicing elit quos iusto dolores.Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    },
    {
      id: 3,
      image: endişeli,
      title: "Endişeli",
      description: "Deleniti animi similique accusantium praesentium?Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    },
    {
      id: 4,
      image: üzgün,
      title: "Üzgün",
      description: "Additional description for the fourth info box.Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    },
    {
      id: 5,
      image: depresif,
      title: "Depresif",
      description: "More descriptive text for the fifth info box.Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    },
    {
      id: 6,
      image: stresli,
      title: "Stresli",
      description: "Continuing with our lorem ipsum journey.Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    },
    {
      id: 7,
      image: yetersiz,
      title: "Yetersiz",
      description: "The final info box in our infinite scroll collection.Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    },
    {
      id: 8,
      image: umutlu,
      title: "Umutlu",
      description: "Deleniti animi similique accusantium praesentium?Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    },
    {
      id: 9,
      image: kararsız,
      title: "Kararsız",
      description: "Additional description for the fourth info box.Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    },
    {
      id: 10,
      image: mutlu,
      title: "Mutlu",
      description: "More descriptive text for the fifth info box.Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    },
    {
      id: 11,
      image: kızgın,
      title: "Kızgın",
      description: "Continuing with our lorem ipsum journey.Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    },
  ];
  

  // Her sayfada farklı içerik için basit bir generator
  return baseBoxes.map((box, index) => ({
    ...box,
    id: `${page}-${index}-${Date.now()}`,
    title: `${box.title}`,
    description: `${box.description}`
  }));
};

const InfoBoxes = () => {
  const [boxes, setBoxes] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const containerRef = useRef(null);
  const observerRef = useRef(null);

  // Yeni kutuları yükleme fonksiyonu
  const loadMoreBoxes = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const newBoxes = await fetchInfoBoxes(page);
      
      // Eğer yeni kutular varsa ekle
      if (newBoxes.length > 0) {
        setBoxes(prevBoxes => [...prevBoxes, ...newBoxes]);
        setPage(prevPage => prevPage + 1);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Kutular yüklenirken hata oluştu:", error);
    } finally {
      setLoading(false);
    }
  }, [page, loading, hasMore]);

  // İlk yükleme
  useEffect(() => {
    loadMoreBoxes();
  }, []);

  // Scroll izleme ve infinite scroll için intersection observer
  useEffect(() => {
    if (!containerRef.current) return;

    const options = {
      root: containerRef.current,
      rootMargin: "0px",
      threshold: 1.0
    };

    observerRef.current = new IntersectionObserver(entries => {
      const first = entries[0];
      if (first.isIntersecting && hasMore && !loading) {
        loadMoreBoxes();
      }
    }, options);

    // Son kutuyu izle
    const currentObserver = observerRef.current;
    const children = containerRef.current.children;
    if (children.length > 0) {
      currentObserver.observe(children[children.length - 1]);
    }

    return () => {
      if (currentObserver) currentObserver.disconnect();
    };
  }, [loadMoreBoxes, hasMore, loading, boxes]);

  const scrollLeft = () => {
    if (containerRef.current) {
      const box = containerRef.current.firstChild;
      if (box) {
        // Kutunun toplam genişliği (kutunun genişliği + gap)
        const boxWidth = box.offsetWidth + parseInt(window.getComputedStyle(containerRef.current).gap || 0);
        const newScrollLeft = containerRef.current.scrollLeft - boxWidth;
        containerRef.current.scrollTo({
          left: newScrollLeft,
          behavior: "smooth",
        });
      }
    }
  };
  
  const scrollRight = () => {
    if (containerRef.current) {
      const box = containerRef.current.firstChild;
      if (box) {
        // Kutunun toplam genişliği (kutunun genişliği + gap)
        const boxWidth = box.offsetWidth + parseInt(window.getComputedStyle(containerRef.current).gap || 0);
        const newScrollLeft = containerRef.current.scrollLeft + boxWidth;
        containerRef.current.scrollTo({
          left: newScrollLeft,
          behavior: "smooth",
        });
      }
    }
  };
  

  return (
    <div className="bg-custom-radial">
    <div className="relative w-[80%] sm:w-[82%] mx-auto flex items-center ">
      {/* Sol navigasyon butonu */}
      <button 
        onClick={scrollLeft}
        className="absolute left-2 z-10 bg-black/40  0 rounded-full p-4 "
      >
        <ChevronLeft className="text-white" size={24} />
      </button>

      {/* Sağ navigasyon butonu */}
      <button 
        onClick={scrollRight}
        className="absolute right-2 z-10 bg-black/40 rounded-full p-4"
      >
        <ChevronRight className="text-white" size={24} />
      </button>

      {/* Kaydırılabilir konteyner */}
      <div 
        ref={containerRef}
        className="overflow-x-scroll hide-scrollbar no-scrollbar "
        style={{ 
          display: 'flex', 
          paddingTop: '5rem',
          paddingBottom: '5rem',
          gap: '1.25rem'
        }}
      >
        <AnimatePresence>
          {boxes.map((box, index) => (
            <motion.div
              key={box.id}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ 
                duration: 0.3,
                ease: "easeInOut"
              }}
              className="flex-shrink-0 w-[300px] text-white text-center bg-white bg-opacity-5 backdrop-blur-sm p-4 rounded-xl"
            >
              <div className="info-box-head  flex flex-col items-center">
                <div className="info-box-img py-2">
                  <img
                    className="h-[6rem] w-[6rem] object-cover rounded-full"
                    loading="lazy"
                    src={box.image}
                    alt={box.title}
                  />
                </div>
                <div className="info-box-title text-2xl">
                  {box.title}
                </div>
              </div>
              <div className="info-box-desc mt-4">
                {box.description}
              </div>
            </motion.div>
          ))}
        </AnimatePresence>

        {/* Yükleniyor göstergesi */}
        {loading && (
          <div className="flex items-center justify-center w-full p-4">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-white"></div>
          </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default InfoBoxes;