import { useState, useEffect, useMemo } from "react"; 
import firstImg from "../hover-1.png"; 
import secondImg from "../hover-2.png"; 
import thirdImg from "../hover-3.png"; 
import fourthImg from "../hover-4.png"; 
import fifthImg from "../hover-5.png";  

// Her kutucuk için ayrı bir içerik tanımlaması yapabiliriz
const hoverContents = [
  {
    title: "Hypnoterapi",
    description: "Hypnoterapi, en hızlı ve etkili bilinçaltı programlama tekniğidir.  Trans videoları ve frekans müzikleri eşliğinde hazırlanmıştır. (Binoural ses disiplini uygulandığı için kulaklığını takarak dinlemelisin.)"
  },
  {
    title: "Farkındalık",
    description: "Sesli farkındalık bölümü, çalışmaların sesli destek uzantısıdır. Konuya uygun frekans müzikleriyle verilir."
  },
  {
    title: "Nefes",
    description: "Her nefes çalışması için ayrı bir frekans müziği, tanıtıcı bilgi ve görsel destek kullanılmıştır."
  },
  {
    title: "Eft (Emotional Freedom Techniques) ",
    description: "Bu bir meridyen enerji tedavisidir.  Akupuntur gibi doğrudan bedendeki meridyen sistemine uygulanır. Fakat iğneler yerine, ana meridyen hatları, parmak uçlarının küçük vuruşları (Tık) ile uygulanır.Eft egzersizleri pratik, anlaşılır, görsel destekli olarak hazırlanmıştır."
  },
  {
    title: "Çözüm odaklı egzersizler",
    description: "Bu egzersizler bilişsel psikoloji referans alınarak hazırlanmış çözüm odaklı egzersizlerdir. Karar alma, yargılama, düşünme gibi süreçler ele alınarak problemlerin çözümü için pratik çözümler sunar. Bu egzersizleri kaç kere yapacağına (önerilen bir tekrar sayısı mevcut) günün hangi dakikası yapacağına karar verip uygulamadan hatırlatma mesajları alabilirsin."
  }
];

function HoverInfoSection() {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [imageSrc, setImageSrc] = useState(firstImg);
  const [opacity, setOpacity] = useState(1);

  const images = useMemo(() => [firstImg, secondImg, thirdImg, fourthImg, fifthImg], []);

  const [activeDiv, setActiveDiv] = useState("0");

  useEffect(() => {
    if (hoveredCard !== null) {
      setOpacity(0);
      const timeoutId = setTimeout(() => {
        setImageSrc(images[hoveredCard]);
        setOpacity(1);
      }, 350);

      return () => clearTimeout(timeoutId);
    }
  }, [hoveredCard, images]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveDiv((prevDiv) => {
        const nextDiv = (parseInt(prevDiv) + 1) % images.length;
        setHoveredCard(nextDiv); 
        return nextDiv.toString();
      });
    }, 4000);

    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    <div className="bg-black relative">
      <section id="hoverinfo">
        <div className="hover-info grid pt-32 mx-auto grid-cols-1 md:grid-cols-2 w-[90%] md:w-[75%] text-white">
          <div className="hover-info-text">
            <div className="hover-info-head">
              <h1 className="text-4xl font-euclid text-customGray">
                Terapi seçeneklerimiz
              </h1>
              <p>
              </p>
            </div>
            <div className="hover-info-box-container">
              {["0", "1", "2", "3", "4"].map((divIndex) => (
                <div
                  key={divIndex}
                  onClick={() => setActiveDiv(divIndex)}
                  tabIndex={0}
                  className={`hover-info-box w-[100%] shadow-custom-4-sided rounded-lg transition-all delay-600 shadow-customPurple/70 my-4 p-3 cursor-pointer ${
                    activeDiv === divIndex ? "bg-customPurple-light/80" : "bg-none"
                  }`}
                  onFocus={() => setHoveredCard(parseInt(divIndex))}
                  onBlur={() => setHoveredCard(null)}
                >
                  <div className="hover-info-box-title text-customGray text-xl">
                    {hoverContents[divIndex].title}
                  </div>
                  <p className=" leading-1">
                    {hoverContents[divIndex].description}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div className="hover-info-image-container my-auto">
            <div className="hover-info-image">
              <img
                src={imageSrc}
                alt="Hover effect"
                className="mx-auto hidden sm:block md:h-[80vh] transition-opacity duration-500"
                style={{ opacity: opacity }}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HoverInfoSection;