import { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import Star from "./StarIcon";
const testimonials = [
  {
    quote:
      "Açıkçası bu kadar farklı bir uygulama olacağımı düşünmüyordum. Premium üye oldum. Her gün giriyorum. Klostrofobi ile ilgili hipnoz paketi de satın aldım. Asansöre binmeyi başardım. Umarım devam eder.",

    author: "Selin",
  },
  {
    quote:
      "Canlı hipnoz yaptıramayanlar için müthiş bir uygulama. Affediş hipnoz paketini kullanmaya başladım. Yeni başladım ama kendimi daha iyi hissetmeye başladım.",

    author: "Merve",
  },
  {
    quote:
      "Gördüğüm en kapsamlı uygulama. Nefes hipnoz eft meditasyon karşılıklı sohbet hersey var. Sorununuz olsun olmasın mutlaka indirin. Ayrıca çok ucuz.",

    author: "Berk",
  },
  {
    quote:
      "İlk satın aldığımda tereddüt etmiştim ama özgüvenle ilgili hipnoz egzersizi belirgin şekilde fark yarattı hayatımda. İnsanlar sana ne oldu diye soruyor. Kendimden daha emin davranıyorum sanırım. Fakat hergün 3 hipnoz egz de dinledim. 14. Gündeyim. Herkese tavsiye ediyorum.",

    author: "Deniz",
  },
  {
    quote:
      "Uykuya dalmada sorun yaşıyordum. Birkaç haftadır kullanıyorum daha hızlı uykuya dalabiliyorum. Bir süre daha devam edeceğim. İşe yaradığını hissediyorum umarım bu şekilde devam eder.",

    author: "Mert",
  },

  {
    quote:
      "Şu an ücretsiz kısmı kullanıyorum. Her gün duygumu girip kaç kişiyle aynı duyguda olduğumu bilmek rahatlatıyor. Bir de sürpriz hediye çalışmalar çıkıyor duyguların içinden :) Premium olucam sanırım. Bir de affetmeyle ilgili hipnoz paketi alacağım. ",

    author: "Mithat",
  },

  {
    quote:
      "Sigarayı bıraktım sonunda. Uygulamanın hayatıma etkisi fazlasıyla oldu giderek daha da gelişeceğine inanıyorum.",

    author: "Elif",
  },
];
const infiniteTestimonials = testimonials.concat(testimonials).concat(testimonials).concat(testimonials);

export default function Reviews() {
  const [position, setPosition] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const visibleItems = 3;
  const itemCount = infiniteTestimonials.length;

  const next = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setPosition((prev) => (prev + 1) % (itemCount - visibleItems + 1));
    setTimeout(() => setIsAnimating(false), 500);
  };

  const prev = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setPosition(
      (prev) =>
        (prev - 1 + (itemCount - visibleItems + 1)) %
        (itemCount - visibleItems + 1)
    );
    setTimeout(() => setIsAnimating(false), 500);
  };

  return (
    <div className="w-[90%] mx-auto p-4 mt-28 sm:mt-52 sm:mb-72">
      <div className="review-head text-white text-5xl mb-4 sm:mb-8">
        Kullanıcı Yorumları
      </div>

      <div className="w-full gap-3 mx-auto">
        <div className="flex-1 overflow-hidden">
          <div
            className="slider-container flex gap-4 transition-transform duration-500 ease-in-out"
            style={{
              transform: `translateX(${
                window.innerWidth < 768
                  ? -position * 100
                  : -position * (100 / visibleItems)
              }%)`,
              width: "100%", // Ensure that the width of the entire container is full-screen width
            }}
          >
            {infiniteTestimonials.map((item, index) => (
              <div
                key={index}
                className="w-[95%] sm:w-[calc(100%/3.11)] flex-none box-border" // Flex-none prevents box stretching, w-full ensures it fits on mobile
              >
                <div className="review-box rounded-2xl grid grid-cols-1 gap-4 p-6 sm:p-12 bg-customPurple-light border border-customPurple-light h-full">
                  <div className="review-star">
                    <Star />
                  </div>
                  <p className="text-xl text-white mb-4">{item.quote}</p>
                  <div className="mt-auto">
                    <p className="font-semibold text-white">{item.author}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="icons flex gap-4 float-right m-4">
          <button
            onClick={prev}
            disabled={isAnimating}
            className="flex-none w-12 h-12 flex items-center justify-center rounded-full bg-white shadow-lg transition-all duration-300 hover:bg-gray-50 hover:scale-110 border border-gray-200"
          >
            <ChevronLeft className="w-6 h-6 text-gray-600" />
          </button>
          <button
            onClick={next}
            disabled={isAnimating}
            className="flex-none w-12 h-12 flex items-center justify-center rounded-full bg-white shadow-lg transition-all duration-300 hover:bg-gray-50 hover:scale-110 border border-gray-200"
          >
            <ChevronRight className="w-6 h-6 text-gray-600" />
          </button>
        </div>
      </div>
    </div>
  );
}
