import React from 'react';
import { Route, Routes } from 'react-router-dom';
import BlogMain from '../components/BlogMain';
import BlogDetail from '../components/BlogDetail';

function Blog() {
  return (
    <div className="bg-gradient-to-b from-blog to-customPurple-premium" >
      <Routes>
        <Route path="/" element={<BlogMain />} />
        <Route path="/:slug" element={<BlogDetail />} />
      </Routes>
    </div>
  );
}

export default Blog;