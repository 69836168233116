import React from "react";
import plant from "../plant.png";
function PremiumSection({ classNames }) {
  return (
    <div className={classNames}>
      <section id="premiumsection">
        {/* <div className="h-[100px] sm:h-[200px]  bg-gradient-to-b from-customWhite  to-customPurple-premium"></div> */}
        <div className="premium-container flex items-center mx-auto w-[90%] sm:w-[80%] pb-120 py-20">
          <div className="premium-container text-left">
            <div className="premium-title text-4xl sm:text-6xl text-white">
              Sana Özel İçerikler ile Hayatını Değiştirmeye Hemen Başla!
            </div>
            <div className="premium-desc mt-3 text-2xl text-white">
              Therapology Premium, sana özel içerikler ve uygulamalar ile
              hayatını değiştirmene yardımcı olur. Hemen başla, farkı hisset!
            </div>
            <div className="hrefImages flex gap-4 pt-6">
              <a
                href="https://apps.apple.com/tr/app/therapoloji-terapi-hipnoz/id1667121878"
                className="shadow-custom-4-sided shadow-customWhite rounded"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/appstore.png`}
                  alt=""
                  className="w-40 rounded "
                  loading="lazy"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.mifosoft.therapoloji&hl=tr"
                className="shadow-custom-4-sided shadow-customWhite rounded"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/googleplay.png`}
                  alt=""
                  className="w-40 rounded"
                  loading="lazy"
                />
              </a>
            </div>
          </div>
          <div className="premium-img hidden sm:block">
            <img src={plant} alt="" className="h-[auto]" loading="lazy" />
          </div>
        </div>
        <div className="copyright bg-customPurple-premium block text-center text-customPurple pb-2 text-sm"> © 2024  <a href="https://manavgatyazilimci.com.tr">Mert Keser</a></div>
      </section>
    </div>
  );
}

export default PremiumSection;
