import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import slugify from "slugify";
import PremiumSection from "./PremiumSection";
import EyeIcon from "./EyeIcon";

// CORS Proxy URL
const CORS_PROXY = "https://therapology.kesermert007.workers.dev/";

// API URL'si
const API_URL = "https://api.therapoloji.freelancer.ist/apiv2/get_bloglar";

// Slugify yapılandırması
const slugifyConfig = {
  replacement: '-',
  remove: undefined,
  lower: true,
  strict: true,
  locale: 'tr',
  trim: true
};

const createBlogUrl = (blog) => {
  const slug = slugify(blog.baslik, slugifyConfig);
  return `/blog-yazilari/${slug}`;
};

function BlogSection() {
  const [blogs, setBlogs] = useState([]);
  const [topBlogs, setTopBlogs] = useState([]);
  const [suggestedBlogs, setSuggestedBlogs] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.post(
          `${CORS_PROXY}${API_URL}`, // CORS proxy ile API URL birleştirilmiş
          {
            lang: "tr",
            blog_kategori_id: -1,
            start: 0,
            end: 999,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Requested-With': 'XMLHttpRequest',
            },
          }
        );

        if (response.data && response.data.data && response.data.data.blog) {
          const sortedBlogs = response.data.data.blog.sort(
            (a, b) => b.okunma_sayisi - a.okunma_sayisi
          );

          const topBlogs = sortedBlogs.slice(0, 5);
          const shuffledBlogs = [...response.data.data.blog]
            .sort(() => Math.random() - 0.5)
            .slice(0, 6);

          setTopBlogs(topBlogs);
          setBlogs(response.data.data.blog);
          setSuggestedBlogs(shuffledBlogs);
        }
      } catch (error) {
        console.error("Blog listesi alınırken hata:", error);
        setError("Blog verileri yüklenemedi");
      }
    };

    fetchBlogs();
  }, []);

  if (error) {
    return <div className="text-red-500">Hata: {error}</div>;
  }

  return (
    <div className="">
      <BlogMain blogs={blogs} topBlogs={topBlogs} suggestedBlogs={suggestedBlogs} />
    </div>
  );
}

function BlogMain({ blogs, topBlogs, suggestedBlogs }) {
  return (
    <div className="">
    <div className="pt-28 text-white w-[90%] :w-[85%] mx-auto">
      <div className="blogPage-title h-32 flex justify-center items-center">
        <h1 className="text-4xl">En Çok Okunan Yazılar</h1>
      </div>

      <div className="blogPage-content gap-1 sm:gap-3 grid grid-cols-1 sm:grid-cols-4 p-4 sm:p-8 bg-customPurple-light/40 shadow-custom-4-sided rounded-3xl">
        {topBlogs.length > 0 ? (
          topBlogs.map((topBlog) => (
            <Link
              to={createBlogUrl(topBlog)}
              key={topBlog.blog_id}
              className="blog-box-top min-h-52 my-1 border border-customPurple-premium relative bg-cover bg-center rounded-xl transition-all delay-100 hover:shadow-custom-rounded-hover2 cursor-pointer"
              style={{
                backgroundImage: `url(https://panel.therapoloji.freelancer.ist/uploads/${topBlog.img_url})`,
              }}
            >
              <div className="blog-box-top-desc absolute bottom-0 rounded-b-xl bg-gradient-to-b from-transparent to-customPurple w-[100%] p-4 text-white">
                <div className="num-of-reads w-16 grid grid-cols-2 bg-black/10 p-2 rounded-2xl">
                  <div className="icon w-5">
                    <EyeIcon />
                  </div>
                  <span className="w-5 text-[13px]">
                    {topBlog.okunma_sayisi}
                  </span>
                </div>
                <span className="text-xl">{topBlog.baslik}</span>
              </div>
            </Link>
          ))
        ) : (
          <p>Yükleniyor...</p>
        )}
      </div>

      <div className="categories-container mx-auto flex justify-center flex-col items-center mt-32">
        <div className="categories-head text-center text-white">
          <h2 className="text-4xl mb-8">Tüm Yazılar</h2>
        </div>

        <div className="allblogs-main-container w-full flex flex-col lg:flex-row gap-4 lg:gap-8">
          <div className="allblogs-content grid grid-cols-1 mt-6 w-full lg:w-[70%]">
            {blogs.length > 0 ? (
              blogs.map((blog) => (
                <Link
                  to={createBlogUrl(blog)}
                  key={blog.blog_id}
                  className="overflow-hidden blog-box rounded-xl transition-all delay-100 hover:shadow-custom-rounded-hover2 cursor-pointer text-customGray bg-customPurple-light/50 shadow-custom-4-sided mb-4 mx-2"
                >
                  <div className="flex flex-col sm:flex-row h-auto sm:h-44">
                    <div className="blog-img relative w-full sm:w-96 sm:min-w-96 h-48 sm:h-auto border-b sm:border-b-0 sm:border-r border-customPurple/20">
                      <img
                        src={`https://panel.therapoloji.freelancer.ist/uploads/${blog.img_url}`}
                        className="absolute inset-0 w-full h-full object-cover"
                        alt={blog.baslik}
                        loading="lazy"
                      />
                    </div>
                    <div className="blog-box-desc p-4 flex flex-col flex-1">
                      <span className="text-customPurple mb-2 text-sm sm:text-base">
                        {blog.blog_kategori}
                      </span>
                      <span className="text-lg sm:text-xl uppercase mb-2 line-clamp-2">
                        {blog.baslik}
                      </span>
                      <p className="text-sm line-clamp-2 sm:line-clamp-3">
                        {blog.aciklama}
                      </p>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <p className="text-center">Yükleniyor...</p>
            )}
          </div>

          <div className="suggested-posts w-full lg:w-[30%] mt-6">
            <div className="bg-customPurple-light/50 rounded-xl p-4 sm:p-6 shadow-custom-4-sided">
              <h3 className="text-xl sm:text-2xl mb-4 sm:mb-6 pb-4 border-b border-customPurple/30">
                Önerilen Yazılar
              </h3>
              <div className="flex flex-col gap-4">
                {suggestedBlogs.map((blog) => (
                  <Link
                    to={createBlogUrl(blog)}
                    key={`suggested-${blog.blog_id}`}
                    className="group"
                  >
                    <div className="flex flex-col">
                      <span className="text-sm text-customPurple mb-1">
                        {blog.blog_kategori}
                      </span>
                      <span className="text-base sm:text-lg group-hover:text-customPurple transition-colors duration-200 line-clamp-2">
                        {blog.baslik}
                      </span>
                    </div>
                    <div className="w-full h-[1px] bg-customPurple/30 mt-4"></div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <PremiumSection classNames="bg-gradient-to-b from-transparent to-blog mt-20" />
    </div>
  );
}

export default BlogSection;
