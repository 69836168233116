import React from "react";
import parallax from "../parallax.png";
function Parallax() {
  return (
    <div
      className="hidden sm:flex sm:items-center sm:justify-center sm:relative sm:h-[60vh] sm:bg-fixed sm:bg-cover"
      style={{ backgroundImage: `url(${parallax})` }}
    >
      <div className="qa-head text-white flex flex-col justify-center items-center text-center h-[100%] w-[100%] sm:absolute sm:top-[0]  ">
        <div className="qa-container sm:bg-gradient-to-b sm:from-customPurple-light shadow-customPurple-light  shadow-custom-rounded p-6 rounded-2xl w-[50%] sm:backdrop-blur-md">
          <div className="qa-title text-4xl font-euc py-6 ">
            <h2
              style={{
                textShadow: "0 0 15px #ddd, 0 0 20px #8B5CF6",
              }}
            >
              Soru cevaplar ve daha fazlası için Uygulamayı dene!
            </h2>
          </div>

          {/* <div className="qa-description">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Consectetur atque quia facilis eius ratione facere dignissimos
              </div> */}

          <div className="hrefImages flex justify-center gap-4">
            <a
              href="https://apps.apple.com/tr/app/therapoloji-terapi-hipnoz/id1667121878"
              className="shadow-custom-4-sided shadow-customWhite rounded"
            >
              <img
                src={`${process.env.PUBLIC_URL}/appstore.png`}
                alt=""
                className="w-40 rounded"
                loading="lazy"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.mifosoft.therapoloji&hl=tr"
              className="shadow-custom-4-sided shadow-customWhite rounded"
            >
              <img
                src={`${process.env.PUBLIC_URL}/googleplay.png`}
                alt=""
                className="w-40 rounded"
                loading="lazy"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Parallax;
