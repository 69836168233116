import { useEffect, useState } from "react";
import reading from "../reading.png";
import arrow from "../arrow-right.svg";
import axios from "axios";
import { Link } from "react-router-dom";
import slugify from "slugify"; // Slugify import edildi

function BlogSection() {
  const [topBlogs, setTopBlogs] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        // CORS Proxy kullanarak API'ye istek at
        const CORS_PROXY = "https://therapology.kesermert007.workers.dev/";
        const API_URL = "https://api.therapoloji.freelancer.ist/apiv2/get_bloglar";

        const response = await axios.post(
          `${CORS_PROXY}${API_URL}`,
          {
            lang: "tr",
            blog_kategori_id: -1,
            start: 0,
            end: 999,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );

        if (response.data && response.data.data && response.data.data.blog) {
          const sortedBlogs = response.data.data.blog.sort(
            (a, b) => b.okunma_sayisi - a.okunma_sayisi
          );
          const topBlogs = sortedBlogs.slice(0, 5);
          setTopBlogs(topBlogs);
        } else {
          setError("Blog verisi uygun formatta değil");
        }
      } catch (error) {
        console.error("Blog listesi alınırken hata oluştu:", error);
        setError("Blog listesi alınamadı");
      }
    };

    fetchBlogs();
  }, []);

  if (error) {
    return <div className="text-red-500">Hata: {error}</div>;
  }

  return (
    <div className="">
      <BlogList topBlogs={topBlogs} />
    </div>
  );
}

function BlogList({ topBlogs }) {
  // Slugify yapılandırması
  const slugifyConfig = {
    replacement: '-',
    remove: undefined,
    lower: true,
    strict: true,
    locale: 'tr',
  };

  return (
    <div className="bg-customWhite mt-48 relative md:pb-28">
      <section id="premiumsecton">
        <div className="custom-shape-divider-top-1726687530">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
        <div className="blog-article w-[90%] md:w-[75%] mx-auto pt-44 pb-20">
          <div className="blog-article-container">
            <div className="blog-article-head text-left py-6 text-4xl">
              Popüler Yazılar
            </div>

            <div className="blog-article-content grid grid-cols-1 md:grid-cols-2 gap-4 text-white">
              <div className="blog-article-img my-auto">
                <img
                  src={reading}
                  alt="Reading"
                  className="h-auto"
                  loading="lazy"
                />
              </div>

              <div className="blog-article-box-container text-black">
                {topBlogs.map((blog) => {
                  const slug = slugify(blog.baslik, slugifyConfig); // Slug oluşturuluyor
                  return (
                    <Link to={`/blog-yazilari/${slug}`} key={blog.blog_id}>
                      <div className="blog-article-box mt-4 h-20 transition-all delay-100 hover:h-24 hover:shadow-custom-rounded-hover border flex items-center p-4 border-black shadow-custom-rounded shadow-black">
                        <div className="blog-article-box-title flex-1">
                          {blog.baslik}
                        </div>
                        <div className="blog-article-box-icon text-right">
                          <img
                            src={arrow}
                            alt="Arrow icon"
                            loading="lazy"
                            className="w-14"
                          />
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BlogSection;
