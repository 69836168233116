import React from "react";
import Hero from "../components/Hero";
import HeroInfo from "../components/HeroInfo";
import HoverInfoSection from "../components/HoverInfoSection";
import QASection from "../components/QASection";
import BlogSection from "../components/BlogSection";
import PremiumSection from "../components/PremiumSection";
import Parallax from "../components/Parallax";
import Reviews from "../components/Reviews";
// import TestRewievs from '../components/TestRewievs'; 
function Home() {
  return (
    <div className="bg-black">
      <Hero />
      <HeroInfo />
      <HoverInfoSection />
      <Reviews />
      <QASection />
      <Parallax />
      <BlogSection />
      <PremiumSection  classNames={"bg-customPurple-premium"}/>
    </div>
  );
}

export default Home;