import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../logo.png";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const scrollToBottom = () => {
    const bottomElement = document.getElementById("premiumsection");
    bottomElement.scrollIntoView({ behavior: "smooth" });
  };
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="fixed bg-white/5 backdrop-blur-md w-full z-20 top-0 start-0 border-b border-gray-200">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        {/* Logo ve Başlık */}
        <Link
          to="/"
          className="flex items-center space-x-3 rtl:space-x-reverse"
        >
          <img src={logo} className="h-8" alt=" Logo" loading="lazy" />
          <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">
            Therapology
          </span>
        </Link>

        {/* Buton ve Hamburger Menüsü */}
        <div className="flex items-center space-x-3 md:space-x-0 rtl:space-x-reverse md:order-2">
          {/* Ücretsiz Dene Butonu */}
          <button
            type="button"
            className="text-white hidden sm:block bg-customPurple-light hover:bg-white hover:text-customPurple-dark focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center"
            onClick={scrollToBottom}
          >
            Ücretsiz Dene!
          </button>

          {/* Hamburger Menüsü */}
          <button
            data-collapse-toggle="navbar-sticky"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="navbar-sticky"
            aria-expanded={isOpen ? "true" : "false"}
            onClick={toggleNavbar}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>

        {/* Navbar Menüsü */}
        <div
          className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${
            isOpen ? "block" : "hidden"
          }`}
          id="navbar-sticky"
        >
          <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0">
            <li>
              <Link
                to="/"
                className="block py-2 px-3 text-white md:bg-transparent md:p-0"
              >
                {" "}
                Ana Sayfa{" "}
              </Link>
            </li>
            <li>
              <Link
                to="/blog-yazilari"
                className="block py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-white md:p-0"
              >
                {" "}
                Blog{" "}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
