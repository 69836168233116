import React from "react";
import app1 from "../1.png";
import app2 from "../3.png";

function Hero() {
  return (
    <div className="pt-16 lg:pt-32 pb-10 h-[100vh] md:h-[115vh] bg-black">
      <section id="hero">
        <div className="hero-container relative w-[90%] grid md:grid-cols-1 lg:grid-cols-3 gap-8 mx-auto">
          {/* Left Image */}
          <img
            src={app1}
            alt=""
            className="hidden md:hidden lg:block lg:w-full lg:max-h-[80vh] object-contain"
            loading="lazy"
          />
          {/* Hero Info */}
          <div className="hero-info flex flex-col justify-center items-center md:col-span-2 lg:col-span-1">
            <h1 className="text-5xl leading-none  lg:text-[3.5rem] mt-16 font-euclid text-customGray text-center">
              Therapoloji, dünyadaki en kapsamlı psikolojik destek
              aplikasyonudur. <br />
            </h1>

            <p className="text-customGray text-5xl pt-4">
              {" "}
              <span className="text-customPurple-light"> Ücretsiz</span> Dene!
            </p>
            <div className="hrefImages flex justify-center gap-4 mt-4 md:mt-8 w-[70%]">
              <a
                href="https://apps.apple.com/tr/app/therapoloji-terapi-hipnoz/id1667121878"
                className="shadow-custom-rounded shadow-white rounded"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/appstore.png`}
                  alt=""
                  className="w-40 rounded"
                  loading="lazy"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.mifosoft.therapoloji&hl=tr"
                className="shadow-custom-rounded shadow-white rounded"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/googleplay.png`}
                  alt=""
                  className="w-40 rounded"
                  loading="lazy"
                />
              </a>
            </div>
            <p className="text-xl py-6 md:text-xl font-euclid text-customGray text-center">
              Spesifik sorunlardan yola çıkarak, duygu ve zihin dünyanı
              iyileştirebileceğin bir yol haritası oluşturur.
            </p>
            {/* App Store Links */}
          </div>
          {/* Right Image */}
          <img
            src={app2}
            alt=""
            className="hidden md:hidden lg:block lg:w-full lg:max-h-[70vh] object-contain mt-0 md:mt-12 lg:mt-16"
            loading="lazy"
          />
        </div>
      </section>
    </div>
  );
}

export default Hero;
