import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
import slugify from "slugify";
import PremiumSection from "./PremiumSection";

// CORS Proxy URL
const CORS_PROXY_DETAIL = "https://therapology-blogdetail.kesermert007.workers.dev/";
const CORS_PROXY = "https://therapology.kesermert007.workers.dev/";

// API URL'si
const API_URL = "https://api.therapoloji.freelancer.ist/apiv2/";

// Skeleton loading komponenti
const BlogSkeleton = () => (
  <div className="min-h-screen bg-gradient-to-b from-blog to-customPurple-premium">
    <div className="w-[90%] md:w-[90%] mx-auto pt-44 animate-pulse">
      <div className="h-12 bg-gray-700/50 rounded-lg w-3/4 mx-auto mb-8" />
      <div className="h-[50vh] bg-gray-700/50 rounded-lg mb-8" />
      <div className="space-y-4 mb-20">
        <div className="h-4 bg-gray-700/50 rounded w-full" />
        <div className="h-4 bg-gray-700/50 rounded w-5/6" />
        <div className="h-4 bg-gray-700/50 rounded w-4/6" />
        <div className="h-4 bg-gray-700/50 rounded w-5/6" />
        <div className="h-4 bg-gray-700/50 rounded w-3/4" />
        <div className="h-4 bg-gray-700/50 rounded w-4/6" />
      </div>
      <div className="mt-20 bg-gradient-to-b from-customPurple-premium to-blog">
        <div className="h-[300px] bg-gray-700/10 rounded-lg" />
      </div>
    </div>
  </div>
);

// Slugify yapılandırması
const slugifyConfig = {
  replacement: '-',
  remove: undefined,
  lower: true,
  strict: true,
  locale: 'tr',
};

// API'den ilgili blogu slug ile getir
const getBlogDetailBySlug = async (slug) => {
  // Blog listesini almak için proxy ile API çağrısı yapıyoruz
  const response = await axios.post(
    `${CORS_PROXY}${API_URL}get_bloglar`,
    {
      lang: "tr",
      blog_kategori_id: -1,
      start: 0,
      end: 999,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      }
    }
  );

  // Slug ile eşleşen blogu bul
  const blog = response.data.data.blog.find(
    (blog) => slugify(blog.baslik, slugifyConfig) === slug
  );

  if (!blog) {
    throw new Error("Blog bulunamadı.");
  }

  // Blog detayını almak için proxy ile API çağrısı yapıyoruz
  const detailResponse = await axios.post(
    `${CORS_PROXY_DETAIL}${API_URL}get_blog_detay`,
    {
      lang: "tr",
      blog_id: blog.blog_id,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      }
    }
  );

  const getData = detailResponse.data.data;
  return getData;
};

function BlogDetail() {
  const { slug } = useParams(); // URL'den slug alıyoruz

  const {
    data: getData,
    isError,
    error,
    isLoading,
  } = useQuery(["blog", slug], () => getBlogDetailBySlug(slug), {
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    retry: 2,
    refetchOnWindowFocus: false,
    suspense: false,
  });

  // Yüklenme durumu
  if (isLoading) {
    return <BlogSkeleton />;
  }

  // Hata durumu
  if (isError) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-blog to-customPurple-premium flex justify-center items-center text-white">
        <p>Blog yüklenirken bir hata oluştu: {error?.message}</p>
      </div>
    );
  }

  // Blog bulunamadıysa
  if (!getData) {
    return (
      <div className="min-h-screen bg-[#0F1117] flex justify-center items-center text-white">
        Blog bulunamadı.
      </div>
    );
  }

  // Blog başarıyla yüklendiyse
  return (
    <div className="min-h-screen bg-gradient-to-b from-blog to-customPurple-premium">
      <div className="detail-container w-[100%] md:w-[90%] mx-auto">
        <div className="blog-detail pt-44 text-white p-10">
          <h1 className="text-white text-4xl sm:text-5xl text-center  mb-8">
            {getData.baslik}
          </h1>
          <div className="relative mb-10">
            <img
              src={`https://panel.therapoloji.freelancer.ist/uploads/${getData.img_url}`}
              className="mx-auto max-h-[80vh] object-contain"
              alt={getData.baslik}
              loading="lazy"
            />
          </div>
          <div
            className="blog-content prose prose-invert max-w-none"
            dangerouslySetInnerHTML={{ __html: getData.aciklama }}
          />
          <style jsx="true">{`
            .blog-content img {
              max-width: 50% !important;
              max-height: 50vh !important;
              margin: 0 auto !important;
              display: block;
            }
          `}</style>
        </div>
      </div>
      <PremiumSection classNames="bg-gradient-to-b from-transparent to-blog mt-20" />
    </div>
  );
}

export default BlogDetail;
