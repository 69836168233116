import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Navbar from "./components/Navbar";
import Home from "./Pages/Home";
import Blog from "./Pages/Blog";
import ScrollToTop from './components/ScrollToTop';
// Query Client yapılandırması
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Varsayılan önbellek ayarları
      staleTime: 5 * 60 * 1000, // 5 dakika
      cacheTime: 30 * 60 * 1000, // 30 dakika
      refetchOnWindowFocus: false, // Sekme değişiminde yeniden istek yapma
      retry: 1, // Hata durumunda 1 kez tekrar dene
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router basename="/">
      <ScrollToTop />

        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog-yazilari/*" element={<Blog />} />
        </Routes>
      </Router>
      
      {/* Development ortamında React Query Devtools'u göster */}
      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
}

export default App;